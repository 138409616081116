var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "wuwow-card"
  }, [_vm._m(0), _c('div', {
    staticClass: "wuwow-card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-lg-4 my-4"
  }, [_c('a', {
    staticClass: "d-flex flex-column align-items-center justify-content-center",
    attrs: {
      "href": "javascript:;"
    },
    on: {
      "click": function ($event) {
        return _vm.link('lst');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "https://cdn2.wuwow.tw/wuwow-junior/vip/self-test-img/lst-tech.png"
    }
  }), _c('span', [_vm._v("lst行動學習系統講解")])])]), _c('div', {
    staticClass: "col-12 col-lg-4 my-4"
  }, [_c('a', {
    staticClass: "d-flex flex-column align-items-center justify-content-center",
    attrs: {
      "href": "javascript:;"
    },
    on: {
      "click": function ($event) {
        return _vm.link('windows');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "https://cdn2.wuwow.tw/wuwow-junior/vip/self-test-img/desktop-test.png"
    }
  }), _c('span', [_vm._v("桌上/筆記型電腦測試")])])]), _c('div', {
    staticClass: "col-12 col-lg-4 my-4"
  }, [_c('a', {
    staticClass: "d-flex flex-column align-items-center justify-content-center",
    attrs: {
      "href": "javascript:;"
    },
    on: {
      "click": function ($event) {
        return _vm.link('mobile');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "https://cdn2.wuwow.tw/wuwow-junior/vip/self-test-img/mobile-test.png"
    }
  }), _c('span', [_vm._v("手機/平板測試")])])])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wuwow-card-head"
  }, [_c('h4', {
    staticClass: "wuwow-card-title"
  }, [_vm._v("自助系統測試")])]);

}]

export { render, staticRenderFns }