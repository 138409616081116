<template lang="pug">
  div
    .wuwow-card
      .wuwow-card-head
        h4.wuwow-card-title 自助系統測試
      .wuwow-card-body
        .row
          .col-12.col-lg-4.my-4
            a.d-flex.flex-column.align-items-center.justify-content-center(href="javascript:;" @click="link('lst')")
              img(src="https://cdn2.wuwow.tw/wuwow-junior/vip/self-test-img/lst-tech.png")
              span lst行動學習系統講解
          .col-12.col-lg-4.my-4
            a.d-flex.flex-column.align-items-center.justify-content-center(href="javascript:;" @click="link('windows')")
              img(src="https://cdn2.wuwow.tw/wuwow-junior/vip/self-test-img/desktop-test.png")
              span 桌上/筆記型電腦測試
          .col-12.col-lg-4.my-4
            a.d-flex.flex-column.align-items-center.justify-content-center(href="javascript:;" @click="link('mobile')")
              img(src="https://cdn2.wuwow.tw/wuwow-junior/vip/self-test-img/mobile-test.png")
              span 手機/平板測試

</template>

<script type="text/javascript">
import lioshutanApi from '@/api';

export default {
  name: 'SelfTest',

  components: {
  },

  data() {
    return {
      profile_base: {},
    };
  },

  created() {
    this.getStudentPorfile();
  },

  methods: {
    async getStudentPorfile(){
      const response = await lioshutanApi.vip.getCompleteProfile();
      this.profile_base = response.data.profile_base.profile;
    },
    link(payload){
      const name = this.profile_base.chinese_name + ' ' + this.profile_base.english_name;
      const email = this.profile_base.email;
      const phone = this.profile_base.work_phone;
      let url;

      if (payload === 'lst') {
        url = 'https://docs.google.com/forms/d/e/1FAIpQLSd9S852w4G10fXDR-zwc9jnPF67OEMQ3-Np6DlaIXjQRjArFg/viewform?entry.1353669494=' + name + '&entry.173491794=' + phone + '&entry.456801985=' + email;
      } else if (payload === 'windows') {
        url = 'https://docs.google.com/forms/d/e/1FAIpQLSeMbjTwzgv-bRS54VJDuNOA_Q6VufwNF4EFzlpPRxFWjAUIfw/viewform?entry.1409798979=' + name + '&entry.1924257529=' + phone + '&entry.2122826935=' + email;
      } else if (payload === 'mobile') {
        url = 'https://docs.google.com/forms/d/e/1FAIpQLSdPZLwZ_pfdtEX9az3U1pRlT-X7hz4q8Ul1cDJiAt7GtBfAMg/viewform?entry.1409798979=' + name + '&entry.1924257529=' + phone + '&entry.2122826935=' + email;
      }
      window.open(url, 'windows open name', 'toolbar=no');
    },
  },
};
</script>
